
.Agotado {
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.Agotado-msj, .Agotado span{
  color: $red;
  font-size: 1.3em;
  background-color: $footerClaro;
  border: 4px solid $footerOscuro;
  border-radius: 15px;
  padding: 2px 10px;
}

.verde-principal{
    background-color: $basePrincipal;
}

.footer-verde-claro{
  background-color: $footerClaro;
}

.footer-verde-oscuro{
  background-color: $footerOscuro;
}

.verde-principal-alto{
    background-color: $basePrincipalAlto;
}

.verde-titulo{
    color:$verdeTitulos;
}

.texto-rojo-Principal{
    color: $baseRojoPrincipal;
}

.texto-blanco{
    color:$blanco;
}

.texto-azul{
  color:$azulMarino;
}

.promociones{
    background-color: $estiloTablaPromocion;
}

.margen-blanco{
 
  margin: auto;
  border: 4px solid $blanco;
}

.promociones-e{
  width:30%; color:#98CBBA;
}

.recibir-promocion{
  padding:5px;
  background-color:#EBEBEB;
}

.subscripcion{
  background-color: #EBEBEB;
}

.detalles-pastel{
  background-color: $teal;
  color: white;
}

.color-promocion{
  color:$baseRojoPrincipal;
}

.cant-car{
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 22px;
  height: 22px;
  font-size: 0.6em;
}

.card-product{
  float: left;
  max-width: 370px;
  text-align: center;
}

.recibir-promocion-e{
  width:30%; 
  background-color:#EBEBEB;
}


.form-control-feedback {
  position: absolute;
  padding: 4.5px;
  pointer-events: none;

}

.estilo-input::placeholder{ /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
  text-align: center;
}

.relleno-columa{
  background-color: $rellenoColumnas;
}

.politicas-privacidad{
  font-size: 1em;
}

 /*
 Tuve que hacer una excepcion aqui y referi por Id este estilo del navbar
 ya que si lo defino como una clase no me respeta los tamaños que defini */
#navBusquedaPrincipal{
  border-radius: 100px;
  height: 20px;
  width: 140px;
  text-align: center;
}

.header .navbar {
  background-color: transparent !important;
}

  
.navbar .navbar-collapse {
  text-align: center;
}

.lineaSeparador {
  color: $baseRojoPrincipal;
  font-size: 55px;
}

.fa-star{
  color: $baseRojoPrincipal;
}

.estilo-pastel{
  border: none;
  border-bottom: 2px solid red;
  text-align: center;
  color: red; 
}

.estilo-pastel::placeholder{
  color: red;
}

.restar-pastel{
  font-size: 20px;
  background-color:$blanco;
  border: 2px solid #f1f1f1;
  border-radius: 10px;

}

.sumar-pastel{
  font-size: 20px;
  background-color:$blanco;
  border: 2px solid #f1f1f1;
  border-radius: 10px;
}

.scroll-overflow{
  overflow: auto;  
  height: 215px;
}

.cantidad-pastel{  
  text-align:center; 
  color: $verdeTitulos;
  font-size: 25px;
  border-radius: 5px;
  min-width: 50px;
}

.form-control:disabled, .form-control[readonly] {
  background-color: $basePrincipalAlto;
  color:white;
  opacity: 1;
}

.pastel{
  text-align:justify; 
  color:white; 
  font-size: 25px;
  background-color: $basePrincipalAlto;
}

.btn-comprar{
  color:white;
  background-color: #8AC3B0;
  font-size: 20px;
}

.recomendaciones{
  color:$basePrincipalAlto;
}

.btn-agregarCarrito{
  color:white;
  background-color: #CDCDCD;
  font-size: 20px;
}

/*vista Especialidad de pasteles */
.especialidad-pastel{
  background-color: $especialidadPastel;
  
}

.contentenido-select select{
  background: $especialidadPastel; 
  color:$blanco;
  font-size: 1.2em;
  border:2px solid $grisPrincipal;
}

.hp-select{
 background-color: $basePrincipalAlto; 
  color:$blanco;
  font-size: 1.5em;
  border:2px solid $basePrincipalAlto;
}

.hr-producto{
  background-color: red;
  height: 1px;
}

.titulo-pastel{
  color: $tituloEspecialidadPastel;
  font-weight: bold;
}

.detalle-pastel{
  border-radius: 1.5em;
  color:$blanco;
}
//ESTILO PARA VISTA PRODUCTOS
.hovereffect {
  padding: 0.5rem;
  float: left;
  overflow: hidden;
  position: relative;
  text-align: center;
  cursor: default;
}
  
  .hovereffect .overlay {
    position: absolute;
    overflow: hidden;
    width: 80%;
    height: 80%;
    left: 10%;
    top: 10%;
    border-bottom: 1px solid #FFF;
    border-top: 1px solid #FFF;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0,1);
    -ms-transform: scale(0,1);
    transform: scale(0,1);
  }
  
  .hovereffect:hover .overlay {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  
  .hovereffect img {
    max-height: 200px;
    min-width: 150px;
    height: 100%;
    position: relative;
    -webkit-transition: all 0.35s;
    transition: all 0.35s;    
  }
  
  .hovereffect:hover .base-img {
    filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.5" /><feFuncG type="linear" slope="0.5" /><feFuncB type="linear" slope="0.5" /></feComponentTransfer></filter></svg>#filter');
    filter: brightness(0.5);
    -webkit-filter: brightness(0.5);
    background:rgba(0,0,0,0.5);
    opacity: 1;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  
  .hovereffect h2 {
    text-transform: uppercase;
    text-align: center;
    position: relative;
    font-size: 15px;
    font-weight: bold;
    background-color: transparent;
    color: $especialidadPastel;
    padding: 0.2em 0; 
    opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,-100%,0);
    transform: translate3d(0,-100%,0);
  }
  
  .hovereffect a, .hovereffect p {
    color: #FFF;
    padding: 0.5em 0;
    opacity: 1;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,100%,0);
    transform: translate3d(0,100%,0);
  }
  
  .hovereffect:hover a, .hovereffect:hover p, .hovereffect:hover h2 {
    opacity: 1;
    filter: alpha(opacity=100);
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
  }


  .fondo-pastel-categoria{
    background-image: url('../../public/img/pastelCategoria.png') ;
    background-size: cover;
  }

  .texto-lista-compras{
   color: $blanco;
   font-weight: bold;
   font-size: 2.0em;
   
  }

 

  .fondo-carrito-compras{
    background-color: $basePrincipalAlto;
  }

  .btn-procederPago{
    background-color: $procederPago;
  }

  .linea-tradicional{
    background-image: url('../../public/img/confetti.png');
    background-size: cover;
    background-color: $fondoInicioLineaTradicional;
    color: white;

    height: 100%;
    justify-content: center;
    padding-bottom: 2rem;
    min-height: 360px;
  }

  .text-Favoritos{
    background-color: #cbcbe5;
  }

  .text-Favoritos p {
    color: white;
    text-shadow: 1px 1px #575757;
    padding-bottom: 0px;
    padding-left: 3px;
    font-size: 1.5rem;
  }
  
  .confeti {
    background-image: url('/img/Confetti_map.png');
    background-size: 30rem;
    background-repeat: repeat;
  }

  .banderin {
    background-image: url('../../public/img/BANDERIN.png');
    background-repeat: no-repeat;
  }

 
  .estado-pendiente {
        background-color:  $estadoPendiente;        
  }
  .estado-cancelado {
        background-color:  $estadoCancelado;
  }
  .estado-aprobado {
        background-color: $estadoAprobado;
  }
  .estado-finalizado{
        background-color: $estadoFinalizado;
  }

  .disable-input{
      background-color:#e9ecef;
      color: #737c82;
  }
 
  .card-style{
    background-color: #ebf4ff;
    border: 1px solid #cfcfcf;
  }

  .space-img-category
  {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 400px;
  }

  .space-img-category img
  {
    height: 100%;
    max-width: 350px;
    max-height: 350px;
  }

  .carousel-img-visual
  {
    max-width: 350px;
    max-height: 350px;
    margin: auto;
  }
  
.sup-promo
{
  top: 0;
  padding-top: 2.0rem;
  background-color: #ebebeb;
  z-index: 9999;
}


.Subcribirse
{
  font-family: "American Purpose";
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 80%;
  letter-spacing: -1px;
}

.Sub-politicas
{
  font-size: 0.8rem;
}

.sup-promo input[type=submit]{
  font-size: 0.8rem;
  padding: 0.4rem 0.3rem;
}

.aviso-Color{
  color: $orange; 
}

.img-visa-master{
  max-height: 64px;
}

.img-min{
  max-height: 200px;
  min-width: 150px;
  height: 100%;
  text-align: center;
}

.img-min img {
  max-width: 100%;
  max-height: 150px;
}

.btn-required-cancel
{
  color: white;
  background-color: #c1c1c1;
  border-color: #6c757d;
}

.Header-Cat{
  background-color: #60b88f;
  background-image: url('/img/Confetti_map.png');
  background-size: 20rem;
  background-repeat: repeat;
  width: 100%;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.Header-Cat h1{
  font-family: "Comic Sans MS";
  font-style: oblique;
  font-weight: bold;
}

.Resumen-Alert
{
  color: #e3342f;
  font-size: 1.4rem;
}

.btn-facebook, .btn-google
{
  color: white;
  &:hover, &:active, &:focus{
    color: white;
  }
}

.btn-facebook{
  background-color: $facebook-color;
  border-color: darken($facebook-color, 3%);
}

.btn-google{
  background-color: $google-color;
  border-color: darken($google-color, 3%);
}


.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.separator::before {
  margin-right: .25em;
}

.separator::after {
  margin-left: .25em;
}