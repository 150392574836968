// Fonts
@font-face{
    font-family: "American Purpose";
    src: asset('fonts/American Purpose.ttf') format("truetype"),
            asset('fonts/American Purpose.otf');
}


// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// Variabes para colores de Redes Sociales
$facebook-color: #3b5998;
$google-color: #dd4b39;

//Variables para colores Principales de la pagina
$basePrincipal: #64b892;
$basePrincipalAlto: #64b892;
$baseRojoPrincipal: #DD6163;
$grisPrincipal: #CDCDCD;
$grisSecundario: #e7e1e1;
$blanco:#FFFFFF;
$verdeTitulos: #648f81;
$estiloTablaPromocion: #EBEBEB;
$rellenoColumnas:#E3E3E2;
$especialidadPastel:#01AFF7;
$tituloEspecialidadPastel: brown;
$procederPago: rgb(205, 230, 66);
$fondoInicioLineaTradicional: #64B892;
$azulMarino: #3490dc;

$estadoPendiente: #ffe7bd;
$estadoCancelado: #eca094;
$estadoAprobado:  #7eddff;
$estadoFinalizado: #90d8a0;

$footerClaro:#97CBB8;
$footerOscuro:#8AC5B0;


